<template>
  <div class="m-login-form">
    <div class="row">
      <div class="col-md-12 d-flex">
        <h4 class="m-login-form__headline py-3 font-primary semibold">
          {{ $t('forgotPasswordHeadline','Glemt adgangskode') }}
        </h4>
      </div>
      <template v-if="!requestedSuccessfully">
        <div class="col-md-12 d-flex">
          <p>{{ $t('inputPasswordAndGetNewPassword', 'Indsæt email nedenfor, og få tilsendt email med instrukser til at få gendannet din adgangskode') }}</p>
        </div>
        <div class="col-md-12 d-flex">
          <base-input
            v-model="formData.email"
            v-validate="'required|email'"
            type="text"
            :label="$t('EmailLabel', 'Email')"
            name="Email"
            :error-message="$t('EmailError', 'Gyldig email er påkrævet')"
            :show-feedback-icon="true"
            aria-label="email"
          />
        </div>
        <div class="col-md-12 d-flex mt-4">
          <button-with-loader
            :loading="isLoading"
            :text="$t('resetPassword','Nulstil adgangskode')"
            class="-primary px-12 semibold"
            @click.native="requestNewPassword"
          />
        </div>
        <div v-if="showError" class="col-md-12 d-flex fade-in mt-4">
          <p class="bg-red color-white p-4 d-block">
            <span class="semibold pb-2 d-block">{{ $t('sorry','Vi beklager') }}</span>
            {{ $t('requestPasswordError','Der skete desværre en fejl, prøv igen senere.') }}
          </p>
        </div>
      </template>
      <div v-else class="col-md-12 d-flex">
        <p>{{ $t('resetEmailSent', 'Vi har nu forsøgt at sende dig en email med instrukser til at få gendannet din adgangskode') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/atoms/BaseInput';
import ButtonWithLoader from '@/components/molecules/ButtonWithLoader.vue';

export default {
  name: 'ResetPasswordForm',
  components: {
    BaseInput,
    ButtonWithLoader
  },
   data () {
     return {
       formData: {
         email: '',
       },
       isLoading: false,
       requestedSuccessfully: false,
       showError: false,
     }
   },
   computed: {
     currentCulture () {
      return this.$store.getters['site/getCurrentCulture'];
    },
   },
   methods: {
    async requestNewPassword () {
      this.isLoading = true;
      this.showError = false;

      try {
        await this.$repositories.authentication.resetPassword(this.currentCulture, this.formData.email);
        this.requestedSuccessfully = true;
      } catch (error) {
        this.showError = true;
      }

      this.isLoading = false;
    }
  }
};
</script>
