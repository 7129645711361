<template>
  <base-button
    v-bind="$attrs"
    v-on="$listeners"
  >
    <base-loader v-if="loading" :loading="true" />
    <span v-else>{{ text }}</span>
  </base-button>
</template>

<script>
import BaseLoader from '@/components/atoms/BaseLoader.vue'
import BaseButton from '@/components/atoms/BaseButton.vue'
export default {
  name: 'ButtonWithLoader',
  components: {
    BaseButton,
    BaseLoader
  },
  props: {
    /**
     * Shows the loader on top of the existing content
     */
    loading: {
      default: true,
      type: Boolean,
    },
    text: {
      default: '',
      type: String,
    },
  }
}
</script>
