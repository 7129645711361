<template>
  <div class="m-login-form">
    <div v-if="!isLoggedIn" class="row">
      <div class="col-md-12 d-flex">
        <h4 class="m-login-form__headline py-3 font-primary">
          {{ $t('alreadyCustomer','Jeg er allerede kunde') }}
        </h4>
      </div>
      <div class="col-md-12 d-flex mb-3">
        <p>{{ $t('alreadyCustomerLogIn','Log ind her hvis du allerede er oprettet som kunde') }}</p>
      </div>
      <template v-if="isUserWithoutPassword">
        <div class="col-12">
          <p class="font-weight-bold">
            {{ $t('userWithoutPassword','Vi kan se at du er oprettet som kunde. Vi har fået nyt system, og vi beder dig derfor om at trykke på "Glemt dit password" nedenfor for at få oprettet et nyt password.') }}
          </p>
        </div>
      </template>
      <template v-else>
        <div class="col-12">
          <form>
            <fieldset>
              <base-input
                v-model="formData.email"
                v-validate="'required|email'"
                type="text"
                :label="$t('EmailLabel', 'Email')"
                name="Email"
                :error-message="$t('EmailError', 'Gyldig email er påkrævet')"
                :show-feedback-icon="true"
                aria-label="email"
                @keyup.enter="login"
              />
              <base-input
                v-model="formData.password"
                v-validate="'required'"
                type="password"
                :label="$t('PasswordLabel', 'Password')"
                name="password"
                :error-message="$t('PasswordError', 'Password er påkrævet')"
                :show-feedback-icon="true"
                aria-label="password"
                @keyup.enter="login"
              />
              <base-checkbox
                v-model="formData.stayLoggedIn"
                name="stayLoggedIn"
                :label="$t('stayLoggedIn', 'Forbliv logget ind')"
                :disabled="false"
                class="mb-4"
              />
            </fieldset>
          </form>
        </div>
        <div class="col-md-12 d-flex">
          <button-with-loader
            :loading="isLoading"
            :text="$t('logIn','Log ind')"
            class="-primary px-12 semibold"
            @click.native="login"
          />
        </div>
      </template>
      <div v-if="attemptFailed" class="col-md-12 d-flex fade-in mt-4">
        <p class="bg-red color-white p-4 d-block">
          <span class="semibold pb-2 d-block">{{ $t('sorry','Vi beklager') }}</span>
          {{ $t('notLoggedIn','vi kunne ikke finde brugernavn / password kombination') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import BaseInput from '@/components/atoms/BaseInput';
import BaseCheckbox from '@/components/atoms/BaseCheckbox';
import ButtonWithLoader from '@/components/molecules/ButtonWithLoader.vue';
import { trackFormSubmission } from '@/utils/enhancedTracking'
import LoginMixin from '@/mixins/loginMixin';

export default {
  name: 'LoginForm',
  components: {
    BaseInput,
    BaseCheckbox,
    ButtonWithLoader
  },
  mixins: [LoginMixin],
  props: {
    context: {
      type: String,
      default (){
        return ''
      }
    }
  },
  data () {
    return {
      formData: {
        email: '',
        password: '',
        stayLoggedIn: false,
      },
      isLoading: false,
      attemptFailed: false,
      isUserWithoutPassword: false
    }
  },
  computed: {
    userImpersonationLink () {
      return this.$store.getters['site/getSiteSettings']?.userImpersonationLink?.url;
    },
    resetPasswordUrl () {
      return this.$store.getters['site/getEmailSettings']?.resetPasswordPage?.url;
    }
  },
  methods: {
    async login () {
      const validatorResult = await this.$validator.validate();
      if (!validatorResult) return;

      this.isLoading = true;
      try {
        this.attemptFailed = false;
        const data = await this.$store.dispatch('user/user/login', { formData: this.formData });
        if (!data.hasPassword) {
          this.isUserWithoutPassword = true;
          return
        }

        trackFormSubmission('Login', 'Content', this.formData.email);

        if (data.properties?.customerNumbers) {
          this.$router.push({
            path: this.userImpersonationLink || '/min-konto/login-som-kunde'
          });
        } else if (this.context !== 'checkout') {
          this.$router.push({
            path: this.$nuxt.context.from.path === this.resetPasswordUrl ? '/' : this.$nuxt?.context?.from?.fullPath || '/'
          });
        }
      } catch (error) {
        this.attemptFailed = true;
      }
      this.isLoading = false;
    },
    logout () {
      this.password = '';
      this.email = '';
      this.attemptFailed = false;
      this.$store.dispatch('user/user/logout');
    }
  }
};
</script>
