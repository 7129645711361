<template>
  <div
    class="a-checkbox"
    :class="{
      'is-active': isChecked,
      'is-disabled': disabled,
      '-invalid' : invalid
    }"
  >
    <label class="a-checkbox__container">
      <input
        :id="id"
        type="checkbox"
        :name="name"
        :value="value"
        :checked="isChecked"
        :disabled="disabled"
        class="a-checkbox__input"
        @change="inputHandler"
      >
      <!-- @slot Custom check mark markup -->
      <slot name="checkmark" v-bind="{ isChecked, disabled }">
        <div class="a-checkbox__checkmark" :class="{ 'is-active': isChecked }">
          <base-icon v-if="isChecked" icon="checkmark" />
        </div>
      </slot>
      <!-- @slot Custom label markup -->
      <slot name="label" v-bind="{ label, isChecked, disabled }">
        <div v-if="label" class="a-checkbox__label" v-html="label"><!-- Label --></div>
      </slot>
    </label>
  </div>
</template>
<script>
import BaseIcon from '@/components/atoms/BaseIcon';

export default {
  name: 'BaseCheckbox',
  components: {
    BaseIcon
  },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [Array, Boolean],
      default: () => []
    },
    id: {
      type: String,
      default: ''
    },
    invalid:  {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isChecked () {
      if (typeof this.selected === 'boolean') {
        return this.selected;
      } else {
        return this.selected.includes(this.value);
      }
    }
  },
  methods: {
    inputHandler () {
      if (typeof this.selected === 'boolean') {
        this.$emit('change', !this.selected);
      } else {
        let selected = [...this.selected];
        if (selected.includes(this.value)) {
          selected = selected.filter(value => value !== this.value);
        } else {
          selected.push(this.value);
        }
        this.$emit('change', selected);
      }
    }
  }
};
</script>
