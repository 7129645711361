<template>
  <div class="o-login-box row">
    <div v-if="showLogin" class="col-md-6" :class="{'col-md-12' : isLoggedIn}">
      <login-form :context="context" />
      <div class="row">
        <div class="col-md-12 d-flex">
          <base-button
            v-if="!isLoggedIn"
            class="text-body text-small text-right mb-7 mt-6 -as-link text-small font-weight-light"
            @click="showLogin = !showLogin"
          >
            {{ $t('forgotpassword', 'Glemt dit password?') }}
          </base-button>
        </div>
      </div>
    </div>
    <div v-else-if="!isLoggedIn" class="col-md-6">
      <reset-password-form />
      <div class="row">
        <div class="col-md-12 d-flex">
          <base-button
            class="text-body text-small text-right mb-7 mt-6 -as-link text-small font-weight-light"
            @click="showLogin = !showLogin"
          >
            {{ $t('goToLogin', 'Gå til login') }}
          </base-button>
        </div>
      </div>
    </div>
    <div v-if="!isLoggedIn" class="col-md-6">
      <div v-if="context === 'checkout'" class="row mb-6">
        <div class="col-md-12 d-flex">
          <h4 class="o-login-box__headline py-3 font-primary semibold">
            {{ $t('continueWithoutLogin', 'Fortsæt som gæst') }}
          </h4>
        </div>
        <div class="col-md-12 d-flex">
          <p>
            {{ $t('continueWithoutLoginText', '') }}
          </p>
        </div>

        <div class="col-md-12 d-flex">
          <base-button
            class="-outline-dark semibold"
            @click="$emit('continue-without-clicked')"
          >
            {{ $t('continueWithoutLoginBtnText', 'Fortsæt som gæst') }}
          </base-button>
        </div>
      </div>
      <div v-if="context === 'checkout'" class="row">
        <div class="col-md-12 d-flex">
          <h4 class="h4 py-3 font-primary semibold">
            {{ $t('newCustomerCheckout','Hvis du ønsker at blive kunde') }}
          </h4>
        </div>
        <div class="col-md-12 d-flex">
          <p>
            {{ $t('newCustomerCheckoutText', 'Er du ikke kunde og ønsker at blive det, kan du efter bestillingen trykke på login øverst og udfylde vores oprettelsesformular.') }}
          </p>
        </div>
        <div v-if="$isNotNullOrUndefined(createUserPageLink)" class="col-md-12 d-flex mt-4">
          <base-link
            :link="$isNotNullOrUndefined(createUserPageLink)"
          >
            <base-button class="-outline-dark semibold w-100">
              {{ $t('newCustomerBtnText', 'Bliv medlem nu') }}
            </base-button>
          </base-link>
        </div>
      </div>
      <div v-else-if="$isNotNullOrUndefined(createUserPageLink)" class="row">
        <div class="col-md-12 d-flex">
          <h4 class="o-login-box__headline py-3 font-primary semibold">
            {{ $t('newCustomer','Ny kunde') }}
          </h4>
        </div>
        <div class="col-md-12 d-flex">
          <p>
            {{ $t('newCustomerText', 'Bliv oprettet som kunde — få ekstra gode tilbud og rabatter og enkel checkout.') }}
          </p>
        </div>
        <div class="col-md-12 d-flex">
          <base-link
            :link="$isNotNullOrUndefined(createUserPageLink)"
          >
            <base-button class="-outline-dark semibold w-100">
              {{ $t('newCustomerBtnText', 'Bliv medlem nu') }}
            </base-button>
          </base-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/molecules/LoginForm';
import ResetPasswordForm from '@/components/molecules/ResetPasswordForm';
import BaseButton from '@/components/atoms/BaseButton';
import BaseLink from '@/components/atoms/BaseLink';
import LoginMixin from '@/mixins/loginMixin';

export default {
  name: 'LoginBox',
  components: {
    LoginForm,
    BaseButton,
    ResetPasswordForm,
    BaseLink
  },
  mixins: [LoginMixin],
  props: {
    context: {
      type: String,
      default (){
        return ''
      }
    }
  },
  data () {
    return {
      showLogin: true
    }
  },
  computed: {
    createUserPageLink () {
      return this.$store.getters['site/getSiteSettings']?.createUserLink?.url;
    }
  }
};
</script>
